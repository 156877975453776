import { whenSeen } from "./github/behaviors/dynamic-elements";
whenSeen('animated-image', () => import('/github/app/components/accessibility/animated-image-element.ts'))
whenSeen('launch-code', () => import('/github/app/components/account_verifications/launch-code-element.ts'))
whenSeen('metric-selection', () => import('/github/app/components/advisories/metric-selection-element.ts'))
whenSeen('severity-calculator', () => import('/github/app/components/advisories/severity-calculator-element.ts'))
whenSeen('create-branch', () => import('/github/app/components/branch/create-branch-element.ts'))
whenSeen('alert-dismissal-details', () => import('/github/app/components/code_scanning/alert-dismissal-details-element.ts'))
whenSeen('sku-list', () => import('/github/app/components/codespaces/advanced_options/sku-list-element.ts'))
whenSeen('create-button', () => import('/github/app/components/codespaces/create-button-element.ts'))
whenSeen('command-palette-page', () => import('/github/app/components/command_palette/command-palette-page-element.ts'))
whenSeen('command-palette-page-stack', () => import('/github/app/components/command_palette/command-palette-page-stack-element.ts'))
whenSeen('deferred-diff-lines', () => import('/github/app/components/diffs/deferred-diff-lines-element.ts'))
whenSeen('readme-toc', () => import('/github/app/components/files/readme-toc-element.ts'))
whenSeen('delayed-loading', () => import('/github/app/components/github/delayed-loading-element.ts'))
whenSeen('feature-callout', () => import('/github/app/components/github/feature-callout-element.ts'))
whenSeen('reopen-button', () => import('/github/app/components/issues/reopen-button-element.ts'))
whenSeen('math-renderer', () => import('/github/app/components/mathjax/math-renderer-element.ts'))
whenSeen('max-value', () => import('/github/app/components/organizations/settings/codespaces_policy_form/constraint_row/max-value-element.ts'))
whenSeen('codespaces-policy-form', () => import('/github/app/components/organizations/settings/codespaces-policy-form-element.ts'))
whenSeen('action-list', () => import('/github/app/components/primer/action_list/action-list-element.ts'))
whenSeen('action-menu', () => import('/github/app/components/primer/action_menu/action-menu-element.ts'))
whenSeen('toggle-switch', () => import('/github/app/components/primer/experimental/toggle-switch-element.ts'))
whenSeen('iterate-focusable-elements.ts', () => import('/github/app/components/primer/modal_dialog/iterate-focusable-elements.ts'))
whenSeen('modal-dialog', () => import('/github/app/components/primer/modal_dialog/modal-dialog-element.ts'))
whenSeen('file-filter', () => import('/github/app/components/pull_requests/file_tree/file-filter-element.ts'))
whenSeen('file-tree', () => import('/github/app/components/pull_requests/file_tree/file-tree-element.ts'))
whenSeen('file-tree-toggle', () => import('/github/app/components/pull_requests/file_tree/file-tree-toggle-element.ts'))
whenSeen('dependabot-alert-row', () => import('/github/app/components/repos/alerts/dependabot-alert-row-element.ts'))
whenSeen('dependabot-alert-table-header', () => import('/github/app/components/repos/alerts/dependabot-alert-table-header-element.ts'))
whenSeen('memex-project-picker', () => import('/github/app/components/repositories/memex/memex-project-picker-element.ts'))
whenSeen('pin-organization-repo', () => import('/github/app/components/repositories/pin-organization-repo-element.ts'))
whenSeen('common.ts', () => import('/github/app/components/search/parsing/common.ts'))
whenSeen('parsing.ts', () => import('/github/app/components/search/parsing/parsing.ts'))
whenSeen('search-input', () => import('/github/app/components/search/search-input-element.ts'))
whenSeen('blackbird.ts', () => import('/github/app/components/search/suggestions/blackbird.ts'))
whenSeen('custom-scopes.ts', () => import('/github/app/components/search/suggestions/custom-scopes.ts'))
whenSeen('default.ts', () => import('/github/app/components/search/suggestions/default.ts'))
whenSeen('history.ts', () => import('/github/app/components/search/suggestions/history.ts'))
whenSeen('languages.ts', () => import('/github/app/components/search/suggestions/languages.ts'))
whenSeen('repos.ts', () => import('/github/app/components/search/suggestions/repos.ts'))
whenSeen('suggestions.ts', () => import('/github/app/components/search/suggestions/suggestions.ts'))
whenSeen('types.ts', () => import('/github/app/components/search/suggestions/types.ts'))
whenSeen('project-picker', () => import('/github/app/components/sidebar/project-picker-element.ts'))
whenSeen('slash-command-toolbar-button', () => import('/github/app/components/slash_commands/slash-command-toolbar-button-element.ts'))
whenSeen('monthly-spend-graph', () => import('/github/app/components/sponsors/orgs/premium_dashboard/monthly-spend-graph-element.ts'))
whenSeen('turbo-staffbar', () => import('/github/app/components/stafftools/turbo/turbo-staffbar-element.ts'))
whenSeen('turbo-staffbar-preview', () => import('/github/app/components/stafftools/turbo/turbo-staffbar-preview-element.ts'))
whenSeen('themed-picture', () => import('/github/app/components/themed_pictures/themed-picture-element.ts'))
whenSeen('tracking-block', () => import('/github/app/components/tracking_block/tracking-block-element.ts'))
whenSeen('tracking-block-omnibar', () => import('/github/app/components/tracking_block/tracking-block-omnibar-element.ts'))
whenSeen('tracking-block-sync.ts', () => import('/github/app/components/tracking_block/tracking-block-sync.ts'))
whenSeen("profile-pins", () => import("./github/profile/profile-pins-element"));
whenSeen("emoji-picker", () => import("./github/emoji-picker-element"));
whenSeen("edit-hook-secret", () => import("./github/behaviors/edit-hook-secret-element"));
whenSeen("insights-query", () => import("./github/insights/insights-query"));
whenSeen("remote-clipboard-copy", () => import("./github/behaviors/remote-clipboard-copy"));
whenSeen("series-table", () => import("./github/insights/series-table"));
whenSeen("line-chart", () => import("./github/insights/line-chart"));
whenSeen("bar-chart", () => import("./github/insights/bar-chart"));
whenSeen("column-chart", () => import("./github/insights/column-chart"));
whenSeen("stacked-area-chart", () => import("./github/insights/stacked-area-chart"));
whenSeen("presence-avatars", () => import("./github/presence/presence-avatars"));
whenSeen("pulse-authors-graph", () => import("./github/graphs/pulse-authors-graph-element"));
whenSeen("stacks-input-config-view", () => import("./github/stacks/stacks-input-config-view"));
whenSeen("community-contributions-graph", () => import("./github/graphs/community-contributions"));
whenSeen("discussion-page-views-graph", () => import("./github/graphs/discussion-page-views"));
whenSeen("discussions-daily-contributors", () => import("./github/graphs/discussions-daily-contributors"));
whenSeen("discussions-new-contributors", () => import("./github/graphs/discussions-new-contributors"));
whenSeen("code-frequency-graph", () => import("./github/graphs/code-frequency-graph-element"));
whenSeen("contributors-graph", () => import("./github/graphs/contributors-graph-element"));
whenSeen("org-insights-graph", () => import("./github/graphs/org-insights-graph-element"));
whenSeen("traffic-clones-graph", () => import("./github/graphs/traffic-clones-graph-element"));
whenSeen("traffic-visitors-graph", () => import("./github/graphs/traffic-visitors-graph-element"));
whenSeen("commit-activity-graph", () => import("./github/graphs/commit-activity-graph-element"));
whenSeen("marketplace-insights-graph", () => import("./github/graphs/marketplace-insights-graph-element"));
whenSeen("user-sessions-map", () => import("./github/settings/user-sessions-map-element"));
whenSeen("reload-after-polling", () => import("./github/behaviors/reload-after-polling-element"));
whenSeen("package-dependencies-security-graph", () => import("./github/graphs/package-dependencies-security-graph-element"));
whenSeen(".js-sub-dependencies", () => import("./github/dependencies"));
whenSeen("network-graph", () => import("./github/graphs/network-graph-element"));
whenSeen("business-audit-log-map", () => import("./github/business-audit-log-map-element"));
whenSeen("inline-machine-translation", () => import("./github/localization/inline-machine-translation-element"));
